<template>
  <div class="echart-display">
    <!-- 搜素部分 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="显示层级" v-if="levelDoopDownList.length > 1">
          <el-select v-model="queryForm.orgId" placeholder="请选择显示层级" @change="changeOrgIdList">
            <el-option
              v-for="item in levelDoopDownList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select
            style="width:320px"
            v-model="queryForm.ids"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            @change="changeProjectList"
          >
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排查日期">
          <el-date-picker
            v-model="queryForm.checkTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="getHiddenDangerPhoto()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select
            v-model="queryForm.hiddenLevelIds"
            placeholder="请选择隐患级别"
            multiple
            collapse-tags
            @change="changeLevelList"
          >
            <el-option v-for="item in dangerLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.statusList"
            placeholder="请选择隐患状态"
            multiple
            collapse-tags
            @change="changeStatusList"
          >
            <el-option v-for="item in dangerStatusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.hiddenTypeIds"
            placeholder="请选择隐患类型"
            multiple
            collapse-tags
            @change="changeDangerTypeList"
          >
            <el-option v-for="item in dangerTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.hiddenCategoryIds"
            placeholder="请选择隐患类别"
            multiple
            collapse-tags
            @change="changeDangerCategoryList"
          >
            <el-option
              v-for="item in dangerCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { selectList } from '../selectList.js';
import { getDataRang, getDataRangByNature } from 'api/common';
import { getHiddenDangerPhoto } from 'api/general-user/prevention-control/danger-treat';
import echartsLine from 'components/EchartsLine';
export default {
  mixins: [selectList],
  components: {
    echartsLine
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      queryForm: {
        ids: [],
        orgId: 1, //默认是集团公司的或者管理员
        hiddenLevelIds: [], //隐患等级
        statusList: [], //隐患状态1待整改 2待复核 3已消除
        hiddenTypeIds: [], //隐患类型
        hiddenCategoryIds: [], //隐患类别
        checkTime: '' //排查时间（时间范围 以逗号隔开）
      },
      xData: [], //图表横坐标
      dataList: [], //图表数据
      //层级下拉列表
      levelDoopDownList: [],
      //项目部下拉列表
      projectList: [],
      dangerStatusList: [
        {
          name: '全选',
          id: ''
        },
        {
          name: '待整改',
          id: 1
        },
        {
          name: '待复核',
          id: 2
        },
        {
          name: '已消除',
          id: 3
        }
      ],
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllLevelFlag: false, //隐患级别(是否全选)
      isCheckedAllStatusFlag: false, //隐患状态（是否全选）
      isCheckedAllTypeFlag: false, //隐患类型（是否全选）
      isCheckedAllCategoryFlag: false //隐患类别（是否全选）
    };
  },
  methods: {
    changeOrgIdList() {
      this.getDataRangByNature();
    },
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.orgId);
          });
          this.queryForm.ids = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.ids = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.ids = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    changeLevelList(id) {
      this.queryForm.statusList = [];
      this.queryForm.hiddenTypeIds = [];
      this.queryForm.hiddenCategoryIds = [];
      if (!this.isCheckedAllLevelFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.dangerLevelList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.hiddenLevelIds = arr;
          this.isCheckedAllLevelFlag = true;
        } else {
          this.queryForm.hiddenLevelIds = id;
        }
      } else {
        this.isCheckedAllLevelFlag = false;
        this.queryForm.hiddenLevelIds = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    changeStatusList(id) {
      this.queryForm.hiddenLevelIds = [];
      this.queryForm.hiddenTypeIds = [];
      this.queryForm.hiddenCategoryIds = [];
      if (!this.isCheckedAllStatusFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.dangerStatusList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.statusList = arr;
          this.isCheckedAllStatusFlag = true;
        } else {
          this.queryForm.statusList = id;
        }
      } else {
        this.isCheckedAllStatusFlag = false;
        this.queryForm.statusList = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    changeDangerTypeList(id) {
      this.queryForm.hiddenLevelIds = [];
      this.queryForm.statusList = [];
      this.queryForm.hiddenCategoryIds = [];
      if (!this.isCheckedAllTypeFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.dangerTypeList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.hiddenTypeIds = arr;
          this.isCheckedAllTypeFlag = true;
        } else {
          this.queryForm.hiddenTypeIds = id;
        }
      } else {
        this.isCheckedAllTypeFlag = false;
        this.queryForm.hiddenTypeIds = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    changeDangerCategoryList(id) {
      this.queryForm.hiddenLevelIds = [];
      this.queryForm.statusList = [];
      this.queryForm.hiddenTypeIds = [];
      if (!this.isCheckedAllCategoryFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.dangerCategoryList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.hiddenCategoryIds = arr;
          this.isCheckedAllCategoryFlag = true;
        } else {
          this.queryForm.hiddenCategoryIds = id;
        }
      } else {
        this.isCheckedAllCategoryFlag = false;
        this.queryForm.hiddenCategoryIds = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    getHiddenDangerPhoto() {
      //图表接口
      let params = cloneDeep(this.queryForm);
      if (params.ids.includes('')) {
        //判断数组中是否有空字符串
        params.ids = params.ids.filter(item => item !== ''); //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.hiddenLevelIds.includes('')) {
        params.hiddenLevelIds = params.hiddenLevelIds.filter(item => item !== '');
      }
      if (params.statusList.includes('')) {
        params.statusList = params.statusList.filter(item => item !== '');
      }
      if (params.hiddenTypeIds.includes('')) {
        params.hiddenTypeIds = params.hiddenTypeIds.filter(item => item !== '');
      }
      if (params.hiddenCategoryIds.includes('')) {
        params.hiddenCategoryIds = params.hiddenCategoryIds.filter(item => item !== '');
      }
      if (params.checkTime) {
        params.checkTime = params.checkTime.join(',');
      }
      getHiddenDangerPhoto(params).then(res => {
        if (res.code === 200) {
          const { dangerSourcePhotoResults, name, allNum } = res.data;
          this.xData = name; //横坐标的值
          if (res.code === 200) {
            if (dangerSourcePhotoResults.length) {
              //只要选择了其他下拉选择，dangerSourcePhotoResults字段就有值
              dangerSourcePhotoResults.forEach((item, index) => {
                item.type = 'bar';
                item.stack = 'bar';
                /* item.itemStyle = {
                  color: function (params) {
                    //这5种颜色写死，其他的随机不用管
                    switch (params.seriesName) {
                      case '一般隐患':
                        return '#FFBB03';
                      case '重大隐患':
                        return '#FC4C2F';
                      case '待整改':
                        return '#FC4C2F';
                      case '待复核':
                        return '#FF8000';
                      case '已消除':
                        return '#36D68D';
                      default:
                        return '';
                    }
                  }
                }; */
                item.color = this.legendFunc(item.name);
                item.barWidth = 20;
                item.data = item.allNum;
                item.id = index; //这里要加，不是全是空，会报错
              });
              this.dataList = dangerSourcePhotoResults;
            } else {
              //只选择了所属项目部
              this.dataList = [
                {
                  data: allNum,
                  type: 'bar',
                  barWidth: 20,
                  backgroundColor: '#1890FF',
                  name: '隐患数量'
                }
              ];
            }
          }
        }
        this.$nextTick(() => {
          this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
        });
      });
    },
    //判断legend图标颜色方法
    legendFunc(name) {
      switch (name) {
        case '一般隐患':
          return '#FFBB03';
        case '重大隐患':
          return '#FC4C2F';
        case '待整改':
          return '#FC4C2F';
        case '待复核':
          return '#FF8000';
        case '已消除':
          return '#36D68D';
        default:
          return `rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(
            Math.random() * 256
          )})`;
      }
    },
    //获取层级
    getDataRang() {
      getDataRang().then(res => {
        if (res.code === 200) {
          const { data } = res;
          if (data.length) {
            this.levelDoopDownList.push(...data);
          } else {
            this.queryForm.orgId = ''; //只是项目部的人，传空
          }
          this.getDataRangByNature();
        }
      });
    },
    //获取项目部
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature({ orgId: this.queryForm.orgId }).then(res => {
        if (res.code === 200) {
          this.projectList = [];
          this.queryForm.ids = [];
          let { data } = res;
          if (data.length) {
            data.unshift({ orgName: '全选', orgId: '' });
            this.projectList.push(...data);
            //这里还要做件事情，把orgId放在一个数组里面
            this.projectList.forEach(item => {
              if (item.orgId !== '') {
                this.queryForm.ids.push(item.orgId);
              }
            });
          }
          //调取图表接口
          this.getHiddenDangerPhoto();
        }
      });
    }
  },
  created() {
    this.configDetailList(true);
    this.getDataRang();
  }
};
</script>

<style scoped lang="less"></style>
