import { configDetailList } from 'api/common';
export const selectList = {
  data() {
    return {
     // projectList: [], //所属项目部
      dangerLevelList: [], //隐患级别
      dangerTypeList: [], //隐患类型
      dangerCategoryList: [] //隐患类别
    };
  },
  methods: {
    configDetailList(boolean = false) {
      //true自定义不加全选，反之
      //获取下拉配置项
      configDetailList({ module: 2 }).then(res => {
        if (res.code === 200) {
          const { two_category, two_level, two_type } = res.data;
          if (boolean) {
            if (two_level.length) {
              two_level.unshift({ name: '全选', id: '' });
            }
          }
          if (boolean) {
            if (two_type.length) {
              two_type.unshift({ name: '全选', id: '' });
            }
          }
          if (boolean) {
            if (two_category.length) {
              two_category.unshift({ name: '全选', id: '' });
            }
          }
          this.dangerLevelList = two_level;
          this.dangerTypeList = two_type;
          this.dangerCategoryList = two_category;
        }
      });
    },
  }
};
